import { WidgetBuilder } from '@wix/app-manifest-builder';

const setGlobalDesignWithEmptyTab = (widgetBuilder: WidgetBuilder) => {
  widgetBuilder.configureWidgetDesign((d) => {
    d.tabs().addTab((t) => {
      t.set({ dependents: [], label: '' });
    });
  });
};

export const setEmptyWidgetInspector = (widgetBuilder: WidgetBuilder) => {
  setGlobalDesignWithEmptyTab(widgetBuilder);
};
