import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { setAllComponentsAsUnselectable } from '@/editor-app/manifest/utils/setAsUnselectable';
import goalAmountWidget from './.component.json';
import { ComponentIds } from './constants';
import { setAsSelectable } from '@/editor-app/manifest/utils/setAsSelectable';
import { initLocaleKeys } from '@/common/utils/locale';
import {
  ConnectedComponentsBuilder,
  WidgetBuilder,
} from '@wix/app-manifest-builder';
import { getRole } from '@/common/utils/getRole';
import { ILocaleKeys } from '@/locale-keys';
import { hideSeoA11y } from '@/editor-app/manifest/utils/hideSeoA11y';
import { setInputFieldGfpp } from '@/editor-app/manifest/utils/InputField';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { helpIds } from '@/common/constants/gfpp';
import { setEmptyWidgetInspector } from '@/editor-app/manifest/utils/setEmptyWidgetInspector';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  const localeKeys = initLocaleKeys(flowAPI.translations.i18n);

  widgetBuilder.set({
    displayName:
      localeKeys.donations.widget.progressBar.inFormComp.displayName(),
    nickname: 'campaignGoal',
  });

  setEmptyWidgetInspector(widgetBuilder);
  setAllComponentsAsUnselectable(widgetBuilder, goalAmountWidget.id);
  setAllDesignOnlyTextComponents(widgetBuilder, localeKeys);
  setProgressBarComponent(widgetBuilder, localeKeys, editorSDK);
};

const setAllDesignOnlyTextComponents = (
  widgetBuilder: WidgetBuilder,
  localeKeys: ILocaleKeys,
) => {
  const designOnlyTextComponents: Record<
    string,
    { displayName: string; containerId?: string }
  > = {
    [ComponentIds.DonatedAmountText]: {
      displayName:
        localeKeys.donations.widget.progressBar.amountRaised.displayName(),
      containerId: ComponentIds.DonatedAmountBox,
    },
    [ComponentIds.GoalAmountText]: {
      displayName:
        localeKeys.donations.widget.progressBar.goalAmount.displayName(),
      containerId: ComponentIds.GoalAmountBox,
    },
    [ComponentIds.DonationCountText]: {
      displayName:
        localeKeys.donations.widget.progressBar.numDonations.displayName(),
      containerId: ComponentIds.DonationCountBox,
    },
    [ComponentIds.TimeLeftText]: {
      displayName:
        localeKeys.donations.widget.progressBar.deadlineCountdown.displayName(),
      containerId: ComponentIds.TimeLeftBox,
    },
    [ComponentIds.PercentageText]: {
      displayName:
        localeKeys.donations.widget.progressBar.percentageFromGoal.displayName(),
      containerId: ComponentIds.PercentageBox,
    },
  };

  Object.entries(designOnlyTextComponents).forEach(
    ([componentId, { displayName, containerId }]) => {
      const hasContainer = !!containerId;
      widgetBuilder.configureConnectedComponents(
        getRole(componentId),
        (componentBuilder) => {
          setDesignOnlyTextComponent(
            componentBuilder,
            displayName,
            !hasContainer,
          );
        },
      );
      if (hasContainer) {
        widgetBuilder.configureConnectedComponents(
          getRole(containerId),
          (containerBuilder) => {
            setRemovableWrappingContainer(containerBuilder, displayName);
          },
        );
      }
    },
  );
};

const setProgressBarComponent = (
  widgetBuilder: WidgetBuilder,
  localeKeys: ILocaleKeys,
  editorSDK: EditorSDK,
) => {
  const displayName = localeKeys.donations.widget.progressBar.displayName();
  widgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.ProgressBar),
    (componentBuilder) => {
      setAsSelectable(componentBuilder, { canBeDeleted: false });
      componentBuilder.set({
        displayName,
      });
      setInputFieldGfpp(
        componentBuilder,
        editorSDK,
        localeKeys.donationForm.gfpp.learnMore(),
        helpIds.DONATION_FORM,
      );
    },
  );
  widgetBuilder.configureConnectedComponents(
    getRole(ComponentIds.ProgressBarBox),
    (containerBuilder) => {
      setRemovableWrappingContainer(containerBuilder, displayName);
    },
  );
};

const setDesignOnlyTextComponent = (
  componentBuilder: ConnectedComponentsBuilder,
  displayName: string,
  canBeDeleted: boolean = true,
) => {
  setAsSelectable(componentBuilder, { canBeDeleted });
  hideSeoA11y(componentBuilder);
  componentBuilder.behavior().set({
    dataEditOptions: 'TEXT_STYLE_ONLY',
  });
  componentBuilder.set({
    displayName,
  });
  componentBuilder
    .gfpp()
    .set('connect', { behavior: 'HIDE' })
    .set('design', { behavior: 'DEFAULT' });
};

const setRemovableWrappingContainer = (
  componentBuilder: ConnectedComponentsBuilder,
  displayName: string,
) => {
  componentBuilder.behavior().set({
    preventHide: false,
  });
  componentBuilder.set({
    displayName,
  });
};
